import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { SalesTypeList } from "@kg-pos/common";
import {
  CreateTitleRewardInput,
  SalesType,
  TitleRewardDataFragment,
  UpdateTitleRewardInput,
  useCreateTitleRewardMutation,
  useUpdateTitleRewardMutation,
} from "__generated__/graphql";
import { MonthYearPicker } from "components/atoms/month-year-picker";
import { RequireField } from "components/atoms/require-field";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";

export const TitleRewardModal: React.VFC<{
  isOpen: boolean;
  onClose: () => void;
  hostId: string;
  data?: TitleRewardDataFragment | null;
  refetch: any;
}> = ({ isOpen, onClose, hostId, data, refetch }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      amount: data?.amount ?? 0,
      percentage: data?.percentage ?? 0,
      salesType: data?.salesType ?? SalesType.SHOKEI,
      startMonth: new Date(data?.startMonth ?? dayjs().startOf("month").toDate()),
      endMonth: data?.endMonth ? new Date(data.endMonth) : null,
      hostId: data?.hostId ?? hostId,
    },
  });
  const [createTitleReward] = useCreateTitleRewardMutation();
  const [updateTitleReward] = useUpdateTitleRewardMutation();
  const toast = useToast();

  const isEdit = !!data;

  const onSubmit = async (
    values: { id: string } & (CreateTitleRewardInput | UpdateTitleRewardInput)
  ): Promise<void> => {
    // ミリ秒がずれててソートに影響する可能性があるので0に揃える
    values.startMonth = dayjs(values.startMonth).startOf("day").toDate();
    if (values.endMonth) {
      values.endMonth = dayjs(values.endMonth).startOf("day").toDate();
    }
    if (isEdit && data) {
      values.id = data?.id;
      await updateTitleReward({
        variables: {
          ...values,
        },
      });
    } else {
      await createTitleReward({
        variables: {
          ...values,
        },
      });
    }

    toast({
      title: `役職手当を${isEdit ? "編集" : "登録"}しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    await refetch();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>役職手当{isEdit ? "編集" : "入力"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="amount" mt={3} isInvalid={!!errors.amount}>
              <FormLabel>
                金額
                <RequireField />
              </FormLabel>
              <Input
                type="number"
                placeholder="金額を入力してください。"
                background="grey.300"
                {...register("amount", {
                  valueAsNumber: true,
                  required: "金額が必要です。",
                  min: {
                    value: 0,
                    message: "0円以上で入力してください。",
                  },
                  max: {
                    value: 1_000_000_000,
                    message: "1,000,000,000円以下で入力してください。",
                  },
                  validate: (value) => {
                    const percentage = getValues("percentage");
                    const amount = value;
                    if (amount === 0 && percentage === 0) {
                      return "金額・％のいずれかは入力してください。";
                    }

                    return true;
                  },
                })}
                // マウスホイールによる数値編集の無効化
                // see: https://qiita.com/kinakomoti-t/items/ce4402419f2018aae7cf
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="percentage" mt={3} isInvalid={!!errors.percentage}>
              <FormLabel>
                パーセンテージ
                <RequireField />
              </FormLabel>
              <Input
                type="number"
                placeholder="パーセンテージを入力してください。"
                background="grey.300"
                {...register("percentage", {
                  valueAsNumber: true,
                  required: "パーセンテージが必要です。",
                  min: {
                    value: 0,
                    message: "0以上で入力してください。",
                  },
                  max: {
                    value: 100,
                    message: "100以下で入力してください。",
                  },
                  validate: (value) => {
                    const amount = getValues("amount");
                    const percentage = value;
                    if (amount === 0 && percentage === 0) {
                      return "金額・％のいずれかは入力してください。";
                    }

                    return true;
                  },
                })}
                // マウスホイールによる数値編集の無効化
                // see: https://qiita.com/kinakomoti-t/items/ce4402419f2018aae7cf
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <FormErrorMessage>{errors.percentage?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="salesType" mt={3} isInvalid={!!errors.salesType}>
              <Text mb={2}>パーセントを</Text>
              <Select
                {...register("salesType", {
                  required: "項目が必要です。",
                })}
              >
                <option value={SalesType.SHOKEI}>{SalesTypeList[SalesType.SHOKEI]}</option>
                <option value={SalesType.SOURIAGE}>{SalesTypeList[SalesType.SOURIAGE]}</option>
              </Select>
              <Text mt={2}>に適用する</Text>
              <FormErrorMessage>{errors.salesType?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="startMonth" mt={3} isInvalid={!!errors.startMonth}>
              <FormLabel>
                適用開始年月
                <RequireField />
              </FormLabel>
              <Controller
                name="startMonth"
                control={control}
                render={({ field }: any) => (
                  <MonthYearPicker
                    startDate={field.value}
                    setStartDate={(date: any) => field.onChange(date)}
                  />
                )}
              />
              <FormErrorMessage>{errors.startMonth?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="endMonth" mt={3} isInvalid={!!errors.endMonth}>
              <FormLabel>適用終了年月（この月を含む）</FormLabel>
              <Controller
                name="endMonth"
                control={control}
                render={({ field }: any) => (
                  <MonthYearPicker
                    startDate={field.value}
                    setStartDate={(date: any) => field.onChange(date)}
                  />
                )}
              />
              <FormErrorMessage>{errors.endMonth?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} colorScheme="blue" isLoading={isSubmitting} type="submit">
              OK
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
